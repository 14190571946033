import { lazy, Suspense, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useAuth } from "./context/AuthContext";
import Layout from "./components/Layout/Layout";
import LoadingAnimation from "./components/LoadingAnimation/LoadingAnimation";
import Protected from "./components/ProtectedRoute/ProtectedRouteClient.component";
import ProtectedRouteAdmin from "./components/ProtectedRoute/ProtectedRouteAdmin.component";
import "@coreui/coreui/dist/css/coreui.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

// Lazy loaded components
const HomePage = lazy(() => import("./pages/Home/Home.page"));
const LeadsProfilePage = lazy(
  () => import("./pages/LeadsProfile/LeadsProfile.page"),
);
const SignInSignUpClient = lazy(
  () => import("./pages/SignInSignUpClient/SignInSignUpClient.page"),
);
const ForgotPasswordPage = lazy(
  () => import("./pages/ForgotPassword/ForgotPassword.page"),
);
const ResendEmailPage = lazy(
  () => import("./pages/ResetPassword/ResendEmailPage"),
);
const SetNewPasswordPage = lazy(
  () => import("./pages/SetNewPassword/SetNewPassword.page"),
);
const IntegrationsPage = lazy(
  () => import("./pages/Integrations/Integrations.page"),
);
const ClientsPage = lazy(() => import("./pages/clients/Clients.page"));
const AccountSettingsPage = lazy(
  () => import("./pages/AccountSettings/AccountSettings.page"),
);
const ContentPage = lazy(() => import("./pages/Content/Content.page"));
const TeamPage = lazy(() => import("./pages/Team/Team.page"));
const HelpCenterPage = lazy(() => import("./pages/HelpCenter/HelpCenter.page"));
const SingleIntegrationPage = lazy(
  () => import("./pages/Integrations/SingleIntegration/SingleIntegration.page"),
);
const IntegratedPlatformPage = lazy(
  () =>
    import("./pages/Integrations/IntegratedPlatform/IntegratedPlatform.page"),
);
const TeamMemberProfilePage = lazy(
  () => import("./pages/Team/TeamMemberProfile.page"),
);
const ReportsPage = lazy(() => import("./pages/Reports/Reports.page"));
const RolesPermissionsPage = lazy(
  () => import("./pages/RolesPermissions/RolesPermissions.page"),
);
const NotFoundPage = lazy(() => import("./pages/NotFound/NotFound.page"));
const UnAuthorizedPage = lazy(
  () => import("./pages/Unauthorized/UnAuthorized.page"),
);
const TermsPage = lazy(() => import("./pages/Terms/Terms.page"));
const AdminLoginPage = lazy(() => import("./pages/AdminAuth/AdminLogin.page"));
const FormsComponent = lazy(
  () => import("./components/IntegratedPlatformPages/Forms.component"),
);
const AdminDashboard = lazy(
  () => import("./pages/AdminDashboard/AdminDashboard.page"),
);
const LayoutComponent = lazy(
  () => import("./components/AdminDashboard/Layout.component"),
);
const Clients = lazy(() => import("./pages/AdminDashboard/Clients"));
const Team = lazy(() => import("./pages/AdminDashboard/Team"));
const Reports = lazy(() => import("./pages/AdminDashboard/Reports"));
const Settings = lazy(() => import("./pages/AdminDashboard/Settings"));
const Setup = lazy(() => import("./pages/AdminDashboard/Setup"));
const Ticket = lazy(() => import("./pages/AdminDashboard/Ticket"));
const Packages = lazy(() => import("./pages/AdminDashboard/Packages"));
const LeadsForClient = lazy(
  () => import("./pages/AdminDashboard/LeadsForClient"),
);
const LeadProfile = lazy(
  () => import("./components/AdminDashboard/LeadProfile"),
);
const TeamMemberAdminProfile = lazy(
  () => import("./components/AdminDashboard/TeamMemberAdminProfile"),
);
const Logs = lazy(() => import("./pages/Logs/Logs"));
const OrderSummary = lazy(() => import("./pages/Payment/OrderSummary"));
const BusinessSuiteChatPage = lazy(
  () => import("./pages/BusinessSuiteChat/BusinessSuiteChat.page"),
);
const SnapKitLoginButton = lazy(
  () => import("./components/SnapChat/SnapKitLoginButton"),
);
const RedirectPage = lazy(() => import("./pages/Redirect/RedirectPage"));
const PrivacyPolicyTempPage = lazy(
  () => import("./pages/PrivacyPolicy/PrivacyPolicyTemp.page"),
);
const IntegratedTiktok = lazy(
  () => import("./pages/Integrations/IntegratedPlatform/IntegratedTiktok"),
);
const ClearBackEndCache = lazy(() => import("./pages/Logs/ClearBackEndCache"));
const TikTokRedirect = lazy(() => import("./pages/TikTok/TikTokRedirect"));

function App() {
  const { user } = useAuth();

  useEffect(() => {
    const clearCacheData = () => {
      caches.keys().then((names) => {
        return Promise.all(names.map((name) => caches.delete(name)));
      });
    };

    clearCacheData();
    window.addEventListener("beforeunload", clearCacheData);

    return () => {
      window.removeEventListener("beforeunload", clearCacheData);
    };
  }, []);

  return (
    <>
      <ToastContainer />
      <ErrorBoundary FallbackComponent={() => <div>Something went wrong!</div>}>
        <Suspense fallback={<LoadingAnimation />}>
          <Routes>
            <Route path={"/"} element={<Layout />}>
              <Route index element={<HomePage />} />
              <Route
                path="/leads/:id"
                element={
                  <Protected isSignedIn={user}>
                    <LeadsProfilePage />
                  </Protected>
                }
              />
              <Route
                path="/leads"
                element={
                  <Protected isSignedIn={user}>
                    <ClientsPage />
                  </Protected>
                }
              />
              <Route
                path="/integrations"
                element={
                  <Protected isSignedIn={user}>
                    <IntegrationsPage />
                  </Protected>
                }
              />
              <Route
                path="/account-settings"
                element={
                  <Protected isSignedIn={user}>
                    <AccountSettingsPage />
                  </Protected>
                }
              />
              <Route
                path="/content"
                element={
                  <Protected isSignedIn={user}>
                    <ContentPage />
                  </Protected>
                }
              />
              <Route
                path="/team"
                element={
                  <Protected
                    isSignedIn={user}
                    requiredPermissions={["member-list"]}
                  >
                    <TeamPage />
                  </Protected>
                }
              />
              <Route
                path="/team/:id"
                element={
                  <Protected
                    isSignedIn={user}
                    requiredPermissions={["member-list"]}
                  >
                    <TeamMemberProfilePage />
                  </Protected>
                }
              />
              <Route path="/support" element={<HelpCenterPage />} />
              <Route path="/terms" element={<TermsPage />} />
              <Route
                path="/meta-business-suite"
                element={
                  <Protected isSignedIn={user}>
                    <BusinessSuiteChatPage />
                  </Protected>
                }
              />
              <Route
                path="/integrations/:id"
                element={
                  <Protected isSignedIn={user}>
                    <SingleIntegrationPage />
                  </Protected>
                }
              />
              <Route
                path="/integrations/facebook/connected"
                element={
                  <Protected isSignedIn={user}>
                    <IntegratedPlatformPage />
                  </Protected>
                }
              />
              <Route
                path="/integrations/tiktok/connected"
                element={
                  <Protected isSignedIn={user}>
                    <IntegratedTiktok />
                  </Protected>
                }
              />
              <Route
                path="/integrations/:id/forms"
                element={
                  <Protected isSignedIn={user}>
                    <FormsComponent />
                  </Protected>
                }
              />
              <Route
                path="/reports"
                element={
                  <Protected isSignedIn={user}>
                    <ReportsPage />
                  </Protected>
                }
              />
              <Route path="/auth/tiktok" element={<TikTokRedirect />} />
              <Route
                path="/permissions"
                element={
                  <Protected
                    isSignedIn={user}
                    requiredPermissions={["role-list"]}
                  >
                    <RolesPermissionsPage />
                  </Protected>
                }
              />
              {/*<Route*/}
              {/*  path="/subscription"*/}
              {/*  element={*/}
              {/*    <Protected*/}
              {/*      isSignedIn={user}*/}
              {/*      requiredPermissions={["subscription-list"]}*/}
              {/*    >*/}
              {/*      <SubscriptionPage />*/}
              {/*    </Protected>*/}
              {/*  }*/}
              {/*/>*/}
              <Route path="/check-out" element={<OrderSummary />} />
              <Route
                path="/test-snap"
                element={
                  <Protected isSignedIn={user}>
                    <div>
                      <SnapKitLoginButton />
                    </div>
                  </Protected>
                }
              />
            </Route>
            <Route path="/client/login" element={<SignInSignUpClient />} />
            <Route path="/checkout" element={<OrderSummary />} />
            <Route path="/tiktok_redir" element={<RedirectPage />}></Route>
            <Route path="/test" element={<RedirectPage />}></Route>
            <Route
              path="/client/forgot-password"
              element={<ForgotPasswordPage />}
            />
            <Route
              path="/client/reset-password"
              element={<ResendEmailPage />}
            />
            <Route
              path="/client/set-new-password"
              element={<SetNewPasswordPage />}
            />
            <Route path="/logs" element={<Logs />} />

            <Route
              path="/admin"
              element={
                <ProtectedRouteAdmin
                  isSignedIn={user}
                  requiredRoles={["Owner"]}
                >
                  <LayoutComponent />
                </ProtectedRouteAdmin>
              }
            >
              <Route
                path="/admin/dashboard"
                element={
                  <ProtectedRouteAdmin
                    isSignedIn={user}
                    requiredRoles={["Owner"]}
                  >
                    <AdminDashboard />
                  </ProtectedRouteAdmin>
                }
              />
              <Route
                path="/admin/clients"
                element={
                  <ProtectedRouteAdmin
                    isSignedIn={user}
                    requiredRoles={["Owner"]}
                  >
                    <Clients />
                  </ProtectedRouteAdmin>
                }
              />
              <Route
                path="/admin/team"
                element={
                  <ProtectedRouteAdmin
                    isSignedIn={user}
                    requiredRoles={["Owner"]}
                  >
                    <Team />
                  </ProtectedRouteAdmin>
                }
              />
              <Route
                path="/admin/packages"
                element={
                  <ProtectedRouteAdmin
                    isSignedIn={user}
                    requiredRoles={["Owner"]}
                  >
                    <Packages />
                  </ProtectedRouteAdmin>
                }
              />
              {/*<Route*/}
              {/*  path="/admin/subscription"*/}
              {/*  element={*/}
              {/*    <ProtectedRouteAdmin*/}
              {/*      isSignedIn={user}*/}
              {/*      requiredRoles={["Owner"]}*/}
              {/*    >*/}
              {/*      <Subscription />*/}
              {/*    </ProtectedRouteAdmin>*/}
              {/*  }*/}
              {/*/>*/}
              <Route
                path="/admin/reports"
                element={
                  <ProtectedRouteAdmin
                    isSignedIn={user}
                    requiredRoles={["Owner"]}
                  >
                    <Reports />
                  </ProtectedRouteAdmin>
                }
              />
              <Route
                path="/admin/settings"
                element={
                  <ProtectedRouteAdmin
                    isSignedIn={user}
                    requiredRoles={["Owner"]}
                  >
                    <Settings />
                  </ProtectedRouteAdmin>
                }
              />
              <Route
                path="/admin/setup"
                element={
                  <ProtectedRouteAdmin
                    isSignedIn={user}
                    requiredRoles={["Owner"]}
                  >
                    <Setup />
                  </ProtectedRouteAdmin>
                }
              />
              <Route
                path="/admin/ticket"
                element={
                  <ProtectedRouteAdmin
                    isSignedIn={user}
                    requiredRoles={["Owner"]}
                  >
                    <Ticket />
                  </ProtectedRouteAdmin>
                }
              />
              <Route
                path="/admin/client/leads/:id"
                element={
                  <ProtectedRouteAdmin
                    isSignedIn={user}
                    requiredRoles={["Owner"]}
                  >
                    <LeadsForClient />
                  </ProtectedRouteAdmin>
                }
              />
              <Route
                path="/admin/client/leads/profile/:id"
                element={
                  <ProtectedRouteAdmin
                    isSignedIn={user}
                    requiredRoles={["Owner"]}
                  >
                    <LeadProfile />
                  </ProtectedRouteAdmin>
                }
              />
              <Route
                path="/admin/team-member/profile/:id"
                element={
                  <ProtectedRouteAdmin
                    isSignedIn={user}
                    requiredRoles={["Owner"]}
                  >
                    <TeamMemberAdminProfile />
                  </ProtectedRouteAdmin>
                }
              />
            </Route>
            <Route path="/admin/login" element={<AdminLoginPage />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path={"/unauthorized"} element={<UnAuthorizedPage />} />
            <Route path="/privacy" element={<PrivacyPolicyTempPage />} />
            <Route path="/clear" element={<ClearBackEndCache />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </>
  );
}

export default App;
