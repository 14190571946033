import {api, BackEnd_EndPoint} from "../config";

const updateSingleLeadApi = async (leadId,leadData) => {
    try {
        const response = await api.put(`${BackEnd_EndPoint}lead/${leadId}`, leadData,{
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export default updateSingleLeadApi;