import HeaderComponent from "./Header/Header.component";
import SidebarComponent from "./Sidebar/Sidebar.component";
import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";
import CenteredModal from "../Shared/modals/CenteredModal/CenteredModal";
import ImportModalContent from "../../pages/Integrations/IntegratedPlatform/ImportModalContent";
import { useIntegrationContext } from "../../context/IntegrationContext";
const Layout = () => {
  const { showIntegrationModal, setShowIntegrationModal } =
    useIntegrationContext();
  return (
    <>
      <HeaderComponent />
      <div className={"d-flex justify-content-center justify-content-md-between"}>
        <SidebarComponent />
        <Container>
          <main className={"mb-5 mx-auto main-content-body"} style={{ marginTop: "1rem" }}>
            <Outlet />
          </main>
        </Container>
      </div>
      <CenteredModal
        show={showIntegrationModal}
        children={
          <ImportModalContent
            handleClose={() => setShowIntegrationModal(false)}
          />
        }
        onHide={() => setShowIntegrationModal(false)}
      />
    </>
  );
};

export default Layout;
