import { createContext, useContext, useState } from "react";
import { FaMeta, FaTiktok } from "react-icons/fa6";
import {
  FaInstagram,
  FaLinkedin,
  FaSnapchatGhost,
  FaTwitter,
} from "react-icons/fa";
import {
  disconnectFromFB,
  FacebookAuth,
  GoogleAuth,
} from "../utils/firebase.config";
import { FcGoogle } from "react-icons/fc";
import updateAccessTokenApi from "../services/integrations/meta/update-access-token.api";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import getUserPagesTikTokAPI from "../services/integrations/tiktok/get-userpages-tiktok";
import downloadTikTokLeadsAPI from "../services/integrations/tiktok/download-leads-tiktok";
import disconnectFromTikTokAPI from "../services/integrations/tiktok/disconnect-api";
import getUserPagesApi from "../services/integrations/meta/user-pages.api";
import { useMetaBusinessChatContext } from "./MetaBusinessChatContext";

// Step 1: Create the Context
const IntegrationContext = createContext();

// Step 2: Define the Provider Component
export const IntegrationProvider = ({ children }) => {
  // Define your states and functions here
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [showIntegrationModal, setShowIntegrationModal] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [advertisers, setAdvertisers] = useState([]);
  const [tiktokIntegrated, setTiktokIntegrated] = useState(null);
  const navigate = useNavigate();
  const [showCancelIntegrationModal, setShowCancelIntegrationModal] =
    useState(false);
  const [showCancelIntegrationModal2, setShowCancelIntegrationModal2] =
    useState(false);
  const [integratedAlready, setIntegratedAlready] = useState(null);
  const [tiktokPages, setTiktokPages] = useState([]);
  const [loadingPages, setLoadingPages] = useState(false);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
  const [showTikTokAuthModal, setShowTikTokAuthModal] = useState(false);
  const [userPages, setUserPages] = useState([]);
  const integrationData = [
    {
      id: 1,
      icon: <FaMeta color="#1877f2" size={60} />,
      successIcon: <FaMeta style={{ color: "#92C020" }} size={80} />,
      platform: "Meta",
      description:
        "Receive new leads from Meta lead ads in your DV Connect account",
    },
    {
      id: 2,
      icon: <FaTiktok color="#00000" size={60} />,
      successIcon: <FaMeta style={{ color: "#92C020" }} size={80} />,
      platform: "Tiktok",
      description:
        "Receive new leads from TikTok lead ads in your DV Connect account",
    },
    {
      id: 3,
      icon: <FcGoogle color="#1877f2" size={46} />,
      successIcon: <FcGoogle style={{ color: "#92C020" }} size={80} />,
      platform: "Google",
      description:
        "Receive new leads from Google lead ads in your DV Connect account",
    },
    {
      id: 4,
      icon: <FaLinkedin color="#1877f2" size={46} />,
      successIcon: <FaLinkedin style={{ color: "#92C020" }} size={80} />,
      platform: "LinkedIn",
      description:
        "Receive new leads from LinkedIn lead ads in your DV Connect account",
    },
    {
      id: 5,
      icon: <FaInstagram color="#e4405f" size={46} />,
      successIcon: <FaInstagram style={{ color: "#92C020" }} size={80} />,
      platform: "Instagram",
      description:
        "Receive new leads from Facebook and Instagram lead ads in your DV Connect account",
    },
    {
      id: 6,
      icon: <FaTwitter color="#1da1f2" size={46} />,
      successIcon: <FaTwitter style={{ color: "#92C020" }} size={80} />,
      platform: "Twitter",
      description:
        "Receive new leads from Twitter ads in your DV Connect account",
    },
    {
      id: 7,
      icon: <FaSnapchatGhost color="#fffc00" size={46} />,
      successIcon: <FaSnapchatGhost style={{ color: "#92C020" }} size={80} />,
      platform: "Snapchat",
      description:
        "Receive new leads from Snapchat ads in your DV Connect account",
    },
  ];

  const handleSignInWithPlatform = async (platform) => {
    let userCredential = undefined;
    try {
      switch (platform) {
        case "Google":
          userCredential = await GoogleAuth();
          break;
        case "Facebook":
          userCredential = await FacebookAuth();
          break;
        case "Tiktok":
          return console.log("tiktok");
        default:
          userCredential = await FacebookAuth();
          break;
      }
      const { user, _tokenResponse } = userCredential;
      if (user && _tokenResponse && "oauthAccessToken" in _tokenResponse) {
        const userData = {
          username: user.email,
          access_token: _tokenResponse.oauthAccessToken,
          flag: platform === "Google" ? "g" : "fb",
        };

        const response = await updateAccessTokenApi(userData);
        const result = await getUserPagesApi();
        setUserPages(result);
        Cookies.set("userPages", JSON.stringify(result));
        if (response.status === 200) {
          const storedUserData = Cookies.get("userData");
          if (storedUserData) {
            const parsedUserData = JSON.parse(storedUserData);

            if (parsedUserData.user) {
              parsedUserData.user["access-token"] =
                _tokenResponse.oauthAccessToken;
              Cookies.set("userData", JSON.stringify(parsedUserData));
              Cookies.set("access_token", _tokenResponse.oauthAccessToken);
            } else {
              console.log("User data structure is invalid.");
            }
          } else {
            console.log("User data not found in cookies.");
          }

          toast.success(response?.data?.message, {
            position: "bottom-right",
            theme: "dark",
          });
          window.location === "/integrations/facebook/connected"
            ? window.location.reload()
            : navigate("/integrations/facebook/connected", {
                state: { source: "integrationContext" },
                replace: true,
              });
        } else {
          toast.error(response?.data?.message, {
            position: "bottom-right",
            theme: "dark",
          });
          console.log("Failed to update access token.");
          window.location === "/integrations/facebook/connected"
            ? window.location.reload()
            : navigate("/integrations/facebook/connected", {
                state: { source: "integrationContext" },
                replace: true,
              });
        }
      } else {
        console.log("Invalid user or token response.");
      }
    } catch (error) {
      if (error.code === "auth/popup-closed-by-user") {
        console.log("Authentication popup closed by the user.");
      } else {
        console.error(error);
      }
    }
  };

  const handleDisconnectFromFB = async (FBAccID) => {
    try {
      const response = await disconnectFromFB(FBAccID);
      setIntegratedAlready(false);
      setShowCancelIntegrationModal(false);

      toast.success(response?.data?.message, {
        position: "bottom-right",
        theme: "dark",
      });

      let user = Cookies.get("userData");
      const token = Cookies.get("access_token");
      Cookies.remove("userPages");
      if (user) {
        user = JSON.parse(user);
        if (token) {
          user.user["access-token"] = null;
          Cookies.set("userData", JSON.stringify(user));
          Cookies.remove("access_token");
          navigate("/integrations");
          if (window.location === "/integrations") {
            window.location.reload();
          }
        } else {
          console.log("Access token not found in user data.");
        }
      } else {
        console.log("User data not found in cookies.");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message, {
        position: "bottom-right",
        theme: "dark",
      });
    }
  };

  const handleDisconnectFromTiktok = async () => {
    try {
      const response = await disconnectFromTikTokAPI();
      setTiktokIntegrated(false);
      setShowCancelIntegrationModal2(false);
      console.log("Disconnection successful:", response);
      Cookies.remove("access_token_tiktok");
    } catch (error) {
      console.error("Error disconnecting from TikTok:", error);
    }
  };

  const handleFetchTikTokPages = async (advertiserID) => {
    setLoadingPages(true);
    try {
      const response = await getUserPagesTikTokAPI(advertiserID);
      setTiktokPages(response?.data?.data?.list);
    } catch (error) {
      console.error("Error fetching TikTok pages:", error);
    } finally {
      setLoadingPages(false); // Set loading state to false regardless of success or failure
    }
  };

  const handleDownloadLeadsTiktok = async (advertiserID, pageID) => {
    const response = await downloadTikTokLeadsAPI(advertiserID, pageID);
    console.log(response);
  };

  // Step 3: Provide the context values
  const contextValues = {
    searchTerm,
    setSearchTerm,
    currentPage,
    setCurrentPage,
    filteredData,
    setFilteredData,
    showIntegrationModal,
    setShowIntegrationModal,
    integrationData,
    accounts,
    setAccounts,
    handleSignInWithPlatform,
    handleDisconnectFromFB,
    showCancelIntegrationModal,
    setShowCancelIntegrationModal,
    showCancelIntegrationModal2,
    setShowCancelIntegrationModal2,
    setIntegratedAlready,
    integratedAlready,
    handleDisconnectFromTiktok,
    setTiktokIntegrated,
    tiktokIntegrated,
    advertisers,
    setAdvertisers,
    tiktokPages,
    setTiktokPages,
    selectedAdvertiser,
    setSelectedAdvertiser,
    loadingPages,
    setLoadingPages,
    handleFetchTikTokPages,
    handleDownloadLeadsTiktok,
    showTikTokAuthModal,
    setShowTikTokAuthModal,
    userPages,
    setUserPages,
  };

  return (
    <IntegrationContext.Provider value={contextValues}>
      {children}
    </IntegrationContext.Provider>
  );
};

// Custom hook to consume the context
export const useIntegrationContext = () => useContext(IntegrationContext);
